/*
@File: Baylin Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader CSS
** - Header CSS
** - Main Banner CSS
** - Features CSS
** - About CSS
** - Services CSS
** - Services Details CSS
** - Project CSS
** - Project Details CSS
** - Feedback CSS
** - Partner CSS
** - Team CSS
** - Subscribe CSS
** - Blog CSS
** - Blog Details CSS
** - Contact CSS
** - Page Title CSS
** - Pagination CSS
** - Sidebar CSS
** - Footer CSS
** - Dark Home CSS
*/

/*================================================
Default CSS
=================================================*/

$font-family: "Poppins", sans-serif;
$main-color: #ff4800;
$paragraph-color: #848484;
$paragraph-color2: #d6d6d6;
$white-color: #ffffff;
$black-color: #000000;
$sub-black-color: #111111;
$transition: 0.5s;
$font-size: 15px;

body {
    font-family: $font-family;
    padding: 0;
    margin: 0;
    font-size: $font-size;
}
p {
    font: {
        size: $font-size;
    }
    color: $paragraph-color;
    line-height: 1.9;
    margin: {
        bottom: 15px;
        top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
img {
    max-width: 100%;
}
.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font: {
        weight: 500;
        family: $font-family;
    }
    margin: {
        top: 15px;
        bottom: 15px;
    }
    color: $black-color;
}
a {
    outline: 0 !important;
    transition: $transition;
    display: block;

    &:hover,
    &:focus {
        text-decoration: none;
        outline: 0;
    }
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    }
}
.d-table {
    width: 100%;
    display: table;
    height: 100%;

    &-cell {
        vertical-align: middle;
        display: table-cell;
    }
}
.bg-gray {
    background-color: #f4f4f4;
}
.uk-dark {
    background-color: $black-color;
}
/*uk-default-btn*/
.uk-button-default {
    border: 2px solid $main-color;
    background-color: $main-color;
    color: $white-color;

    &:hover,
    &:focus {
        background: $white-color;
        color: $white-color;
        border-color: $main-color;
    }
    i {
        margin-left: 3px;
    }
}
.uk-button {
    font: {
        size: 15px;
        weight: 600;
    }
    line-height: unset;
    text-transform: capitalize;
    padding: 13px 32px;
    transition: $transition;
    position: relative;
    z-index: 1;

    &::before,
    &::after {
        content: "";
        position: absolute;
        height: 0;
        width: 51%;
        background: $main-color;
        z-index: -1;
        transition: $transition;
    }
    &::after {
        left: 0;
        top: 0;
    }
    &::before {
        right: 0;
        bottom: 0;
    }
    &:hover,
    &:focus {
        &::before,
        &::after {
            height: 100%;
        }
    }
}
/*uk-optional-btn*/
.uk-button-optional {
    line-height: unset;
    text-transform: capitalize;
    padding: 13px 32px;
    transition: $transition;
    position: relative;
    border: 2px solid $main-color;
    background-color: transparent;
    color: $black-color;
    z-index: 1;
    display: inline-block;
    font: {
        size: 15px;
        weight: 600;
    }
    &:hover,
    &:focus {
        background: $white-color;
        color: $white-color;
        border-color: $main-color;
    }
    i {
        margin-left: 3px;
    }
    &::before,
    &::after {
        content: "";
        position: absolute;
        height: 0;
        width: 51%;
        background: $main-color;
        z-index: -1;
        transition: $transition;
    }
    &::after {
        left: 0;
        top: 0;
    }
    &::before {
        right: 0;
        bottom: 0;
    }
    &:hover,
    &:focus {
        &::before,
        &::after {
            height: 100%;
        }
    }
}
/*section-title*/
.section-title {
    margin-bottom: 60px !important;
    position: relative;

    .bar {
        position: relative;
        z-index: 1;
        background: $main-color;
        width: 55px;
        height: 3px;
        margin: {
            top: 12px;
        }
        transition: $transition;

        &::before,
        &::after {
            content: "";
            position: absolute;
            height: 100%;
            width: 3px;
            top: 0;
            background: $white-color;
            transition: $transition;
        }
        &::after {
            right: 10px;
        }
        &::before {
            right: 20px;
        }
    }
    span {
        margin-bottom: -5px;
        color: $main-color;
        display: block;
        text-transform: uppercase;
    }
    h2 {
        font: {
            size: 34px;
            weight: 700;
        }
    }
    .uk-button {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -25px;
    }
    &.uk-text-center {
        .bar {
            margin: {
                left: auto;
                right: auto;
            }
        }
    }
}
/*uk-input*/
.uk-input,
.uk-select:not([multiple]):not([size]) {
    height: 47px;
}
.uk-input,
.uk-select,
.uk-textarea {
    padding: 10px;
    transition: $transition;

    &:focus {
        border-color: $main-color;
        padding-left: 15px;
    }
}

/*================================================
Preloader CSS
=================================================*/
.uk-preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: $main-color;
    left: 0;
    right: 0;

    .spinner {
        width: 70px;
        height: 70px;
        position: absolute;
        margin: 0 auto;
        right: 0;
        left: 0;
        top: 50%;
        margin-top: -70px;

        .double-bounce1,
        .double-bounce2 {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: $white-color;
            position: absolute;
            top: 0;
            left: 0;
            animation: sk-bounce 2s infinite ease-in-out;
        }
        .double-bounce2 {
            opacity: 0.8;
            animation-delay: -1s;
        }
    }
}
@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}

/*===============================================*/
/*================== News CSS ===================*/
/*===============================================*/

.News-area .card {
    background-color: #fff;
    background-clip: border-box;
    border: 2px solid rgba(0, 0, 0, 0.125);
    width: 350px;
    margin: 10px 10px 0 10px;
    display: inline-block;
    position: relative;
    transition: transform 0.3s, box-shadow 0.3s;
    transform-origin: center bottom;
}

.News-area .card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.News-area .uk-grid {
    margin-left: 0 !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.News-area .details-btn {
    text-align: center;
    width: 25%;
    height: auto;
    border-radius: 5rem;
    font-size: 18px;
    margin: 0 0 1rem 0;
}

@media (max-width: 12000px) {
    .News-area .uk-grid > * {
        padding: 10px !important;
    }
}

.News-Page .News-Container h1 {
    text-align: center;
}
.News-Page .News-Container img {
    width: 650px;
    // margin: auto;
    display: block;
    margin: 1rem auto 1rem auto;
}
.News-Page .News-Container p {
    text-align: justify;
}
.News-Page .News-Container a {
    display: inline-block;
}
.News-Page .News-Container {
    padding: 5rem 7.5rem 5rem 7.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 769px) {
    .News-Page .News-Container {
        padding: 5rem 2.5rem 5rem 2.5rem;
    }
}
@media (max-width: 426px) {
    .News-Page .News-Container h1 {
        font-size: 26px;
    }
    .News-Page .News-Container {
        padding: 2.5rem 1rem 2.5rem 1rem;
    }
}

/*===============================================*/
/*================== FAQ CSS ===================*/
/*===============================================*/

.accordion h1 {
    text-decoration: underline #ff4800;
}
.accordion-item {
    font-weight: bold !important;
}

.accordion-header {
    margin: 0;
    font-weight: bold !important;
}

.accordion-button:focus {
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #ff4800;
}

.accordion-button:focus,
.accordion-button:active {
    border-color: black;
}

.accordion-button {
    margin: 0 !important;
}
.accordion-button:not(.collapsed) {
    background-color: white !important;
    box-shadow: inset 0 cal #ff4800 !important;
}
.accordion-button:focus {
    border-color: #ff4800 !important;
}

.accordion-collapse {
    border-color: red !important;
}


/*================================================
Process CSS
=================================================*/

/*================================================
Header CSS
=================================================*/
.header-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 999;
    padding: {
        top: 20px;
        bottom: 20px;
    }
    .uk-navbar-container {
        &:not(.uk-navbar-transparent) {
            background: transparent;
        }
    }
    &.bg-white {
        background-color: $white-color;

        .uk-container {
            max-width: 100%;
            padding: {
                left: 40px;
                right: 40px;
            }
        }
    }
    &.uk-sticky-fixed {
        padding: {
            top: 20px;
            bottom: 20px;
        }
        &.uk-sticky-below {
            background: $white-color;
        }
    }
    &.uk-active {
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    }
    #navbar-toggle {
        position: absolute;
        right: 0;
        top: 4px;
        width: 35px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        cursor: pointer;
        padding: 0;
        height: 20px;
        min-height: 20px;
        display: none;

        span {
            display: block;
            width: 100%;
            height: 3px;
            background-color: $black-color;
        }
        * + span {
            margin-top: 3px;
        }
    }
}
.navbar {
    .uk-navbar-nav {
        li {
            padding: 0 15px;

            a {
                color: $black-color;
                text-transform: capitalize;
                transition: $transition;
                padding: 0;
                position: relative;
                min-height: 25px;
                z-index: 1;
                font: {
                    size: $font-size;
                    weight: 600;
                    family: $font-family;
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: 0 auto;
                    background: $main-color;
                    width: 0;
                    height: 2px;
                    z-index: -1;
                    opacity: 0;
                    visibility: hidden;
                    transition: $transition;
                }
                svg {
                    margin: 0 -5px 0 0;
                }
                &:hover,
                &:focus,
                &.active {
                    color: $main-color;

                    &::before {
                        opacity: 1;
                        visibility: visible;
                        width: 100%;
                    }
                }
            }
            .uk-dropdown {
                padding: 15px 5px 15px 0;
                display: block;
                opacity: 0;
                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                visibility: hidden;
                transition: $transition;
                top: 120% !important;

                .uk-dropdown-nav {
                    li {
                        margin-bottom: 10px;

                        a {
                            color: $black-color;

                            &::after {
                                position: absolute;
                                left: -15px;
                                top: 0;
                                height: 0;
                                width: 3px;
                                background: $main-color;
                                content: "";
                                opacity: 0;
                                visibility: hidden;
                                transition: $transition;
                            }
                            &::before {
                                display: none;
                            }
                            &:hover,
                            &:focus {
                                color: $main-color;

                                &::after {
                                    opacity: 1;
                                    height: 100%;
                                    visibility: visible;
                                }
                            }
                            &.uk-active {
                                color: $main-color;

                                &::after {
                                    opacity: 1;
                                    visibility: visible;
                                    height: 100%;
                                }
                            }
                        }
                        &.uk-active {
                            a {
                                color: $main-color;

                                &::after {
                                    opacity: 1;
                                    visibility: visible;
                                    height: 100%;
                                }
                            }
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            &:hover {
                .uk-dropdown {
                    opacity: 1;
                    visibility: visible;
                    top: 95% !important;
                }
            }
            &.uk-active {
                a {
                    color: $main-color;

                    &::before {
                        opacity: 1;
                        visibility: visible;
                        width: 100%;
                    }
                }
            }
        }
    }
    .lang {
        margin-left: 6px;

        form {
            select {
                background: transparent;
                border: none;
                font: {
                    size: $font-size;
                    weight: 600;
                }
                color: $black-color;
                outline: 0 !important;
                cursor: pointer;
                box-shadow: unset !important;
            }
        }
    }
}
.uk-sticky-placeholder {
    height: 0 !important;
}
/*Mobile Navbar*/
.mobile-navbar {
    z-index: 99999;

    .uk-navbar-container {
        &:not(.uk-navbar-transparent) {
            background: transparent;
        }
    }
    .uk-offcanvas-bar {
        width: 290px;
        padding: 40px;
        background-color: $black-color;
    }
    .uk-navbar-nav {
        display: block;

        li {
            padding: 10px 0;
            display: block;

            a {
                color: $white-color;
                text-transform: capitalize;
                transition: $transition;
                font: {
                    size: $font-size;
                    weight: 600;
                    family: $font-family;
                }
                padding: 0;
                display: inline-block;
                position: relative;
                min-height: 25px;
                z-index: 1;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: 0 auto;
                    background: $main-color;
                    width: 0;
                    height: 2px;
                    z-index: -1;
                    opacity: 0;
                    visibility: hidden;
                    transition: $transition;
                }
                &:hover,
                &:focus,
                &.uk-active {
                    color: $main-color;

                    &::before {
                        opacity: 1;
                        visibility: visible;
                        width: 100%;
                    }
                }
            }
            .uk-dropdown {
                padding: 15px 5px 15px 0;

                .uk-dropdown-nav {
                    li {
                        margin-bottom: 8px;
                        padding: 0 15px;

                        a {
                            color: $black-color;

                            &::after {
                                position: absolute;
                                left: -15px;
                                top: 0;
                                height: 0;
                                width: 3px;
                                background: $main-color;
                                content: "";
                                opacity: 0;
                                visibility: hidden;
                                transition: $transition;
                            }
                            &::before {
                                display: none;
                            }
                            &:hover,
                            &:focus,
                            &.uk-active {
                                color: $main-color;

                                &::after {
                                    opacity: 1;
                                    height: 100%;
                                    visibility: visible;
                                }
                            }
                        }
                        &.uk-active {
                            a {
                                color: $main-color;

                                &::after {
                                    opacity: 1;
                                    visibility: visible;
                                    height: 100%;
                                }
                            }
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            &.uk-active {
                a {
                    color: $main-color;

                    &::before {
                        opacity: 1;
                        visibility: visible;
                        width: 100%;
                    }
                }
            }
        }
    }
}

/*================================================
Main Banner CSS
=================================================*/
.main-banner {
    height: 750px;
    position: relative;
    z-index: 1;
    background: {
        repeat: no-repeat;
        size: 770px;
        position: 90%;
    }
    &.item-bg2 {
        background: {
            size: 575px;
        }
    }
    &.item-bg3 {
        background: {
            size: 575px;
        }
    }
}
.item-bg1 {
    background-image: url(../../assets/img/home/IIIEX2024.jpg);
}
.item-bg2 {
    background-image: url(../../assets/img/beard-man.png);
}
.item-bg3 {
    background-image: url(../../assets/img/main-women.png);
}
.main-banner-content {
    max-width: 560px;

    h1 {
        position: relative;
        z-index: 1;
        line-height: 70px;
        padding: 5px 0;
        margin: 0;
        font: {
            size: 65px;
            weight: 700;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 100%;
            z-index: -1;
            background: $main-color;
        }
    }
    p {
        text-align: justify;
        margin: {
            top: 25px;
            bottom: 30px;
        }
    }
    a {
        display: inline-block;
    }
    .video-btn {
        margin-left: 20px;
        position: relative;
        top: 4px;
        color: $paragraph-color;
        font-weight: 600;

        .uk-icon {
            width: 45px;
            margin-right: 5px;
            height: 45px;
            line-height: 45px;
            background-color: $white-color;
            border: 2px solid $main-color;
            border-radius: 50%;
            text-align: center;
            color: $main-color;
            transition: $transition;

            svg {
                width: 30px;
            }
        }
        &:hover,
        &:focus {
            color: $main-color;

            .uk-icon {
                background-color: $black-color;
                color: $white-color;
                border-color: $black-color;
            }
        }
    }
}
.banner-with-form {
    .main-banner-content {
        max-width: unset;
    }
    .uk-container {
        .uk-grid {
            margin-top: 30px;
        }
    }
}
.main-banner-form {
    border: 3px solid #111111;
    background: $black-color;
    padding: 35px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    margin-left: 45px;

    form {
        select {
            &.uk-input {
                cursor: pointer;
                line-height: initial;
            }
        }
        label {
            span {
                color: #8a8a8a;
                position: relative;
                top: -3px;
                font-size: 13px;

                a {
                    display: inline-block;
                    color: $main-color;
                }
            }
        }
    }
}
.hero-banner {
    position: relative;
    z-index: 1;
    padding: {
        top: 180px;
        bottom: 80px;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 30%;
        width: 100%;
        z-index: -1;
        bottom: 0;
        background: {
            image: url(../../assets/img/home-shape.png);
            position: top center;
            size: cover;
            repeat: no-repeat;
        }
    }
}
.hero-banner-content {
    margin-top: 30px;

    h1 {
        margin-bottom: 15px;
        font: {
            size: 50px;
            weight: 700;
        }
        span {
            color: $main-color;
        }
    }
    p {
        margin-bottom: 0;
    }
    .btn-box {
        margin-top: 30px;

        .uk-button-optional {
            margin-left: 10px;
            position: relative;
            top: 1px;
        }
    }
}
.banner-section {
    .item {
        padding: 0 !important;
    }
}
.banner-content {
    padding: 50px;
    height: 100vh;
    background-color: #fbf7f7;

    .content {
        max-width: 620px;
        margin: {
            left: auto;
            top: 80px;
        }
    }
    h1 {
        margin-bottom: 15px;
        font: {
            size: 53px;
            weight: 700;
        }
        span {
            color: $main-color;
        }
    }
    p {
        margin-bottom: 0;
        max-width: 535px;
    }
    .btn-box {
        margin-top: 30px;

        .uk-button-optional {
            margin-left: 10px;
            position: relative;
            top: 1px;
        }
    }
}
.banner-image {
    position: relative;
    background: {
        image: url(../../assets/img/banner-img1.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    img {
        display: none;
    }
}
.shape-circle-img1 {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    img {
        animation: movebounce 5s linear infinite;
    }
}
.shape-circle-img2 {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    img {
        animation: movebounce 5s linear infinite;
    }
}
@keyframes movebounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}

/*================================================
Features CSS
=================================================*/
.features-area {
    position: relative;
    margin-top: -50px;
    z-index: 1;
    background: $white-color;

    &.uk-dark {
        background-color: $black-color;
    }
}
.single-features-box {
    background-color: #f7f7ff;
    position: relative;
    z-index: 1;
    padding: 55px 40px;
    transition: $transition;

    .icon {
        color: $main-color;
        transition: $transition;

        i {
            &::before {
                font-size: 45px;
            }
        }
    }
    h3 {
        font: {
            size: 22px;
            weight: 700;
        }
        margin-bottom: 0;
        transition: $transition;
    }
    .dot-img {
        position: absolute;
        right: 5px;
        bottom: 5px;
        z-index: -1;
        transition: $transition;

        img {
            transition: $transition;

            &.white-dot {
                display: none;
            }
        }
    }
    .bar {
        position: relative;
        z-index: 1;
        background: $main-color;
        width: 55px;
        height: 3px;
        transition: $transition;
        margin: {
            top: 7px;
            bottom: 20px;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            height: 100%;
            width: 3px;
            top: 0;
            background: $white-color;
            transition: $transition;
        }
        &::after {
            right: 10px;
        }
        &::before {
            right: 20px;
        }
    }
    p {
        margin-bottom: 0;
        transition: $transition;
    }
    .animation-img {
        img {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            transition: $transition;

            &:nth-child(1) {
                left: 0;
                bottom: 0;
            }
            &:nth-child(2) {
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }
            &:nth-child(3) {
                top: 0;
                left: 0;
            }
            &:nth-child(4) {
                left: 50%;
                transform: translateX(-50%);
                top: 0;
            }
            &:nth-child(5) {
                right: 0;
                top: 0;
            }
            &:nth-child(6) {
                top: 50%;
                transform: translateY(-50%);
                right: 0;
            }
            &:nth-child(7) {
                bottom: 0;
                right: 0;
            }
            &:nth-child(8) {
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
            }
        }
    }
    &:hover,
    &:focus,
    &.active {
        background: $main-color;

        .icon {
            color: $white-color;
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        .dot-img {
            img {
                &.color-dot {
                    display: none;
                }
                &.white-dot {
                    display: block;
                }
            }
        }
        .bar {
            background: $white-color;

            &::after,
            &::before {
                background: $main-color;
            }
        }
    }
    &:hover,
    &:focus {
        .animation-img {
            img {
                opacity: 1;
                visibility: visible;

                &:nth-child(1) {
                    left: -25px;
                    bottom: -24px;
                }
                &:nth-child(2) {
                    left: -35px;
                }
                &:nth-child(3) {
                    top: -15px;
                    left: -16px;
                }
                &:nth-child(4) {
                    top: -28px;
                }
                &:nth-child(5) {
                    right: -25px;
                    top: -26px;
                }
                &:nth-child(6) {
                    right: -25px;
                }
                &:nth-child(7) {
                    bottom: -26px;
                    right: -25px;
                }
                &:nth-child(8) {
                    bottom: -25px;
                }
            }
        }
    }
}
.br-line {
    position: relative;
    overflow: hidden;
    height: 60px;
    width: 3px;
    background: $main-color;
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index: 1;

    &::after,
    &::before {
        content: "";
        position: absolute;
        background: $white-color;
        width: 100%;
        height: 3px;
        left: 0;
        z-index: -1;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-name: MOVE-BG;
    }
    &::after {
        top: 5px;
    }
    &::before {
        top: 15px;
    }
}
@keyframes MOVE-BG {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(60px);
    }
}

/*================================================
About CSS
=================================================*/
.about-area {
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.about-content {
    .section-title {
        margin-bottom: 40px !important;
    }
    .about-text {
        position: relative;
        padding-left: 65px;

        .icon {
            position: absolute;
            left: 0;
            top: 0;
            color: $main-color;

            i::before {
                font-size: 45px;
            }
        }
        h3 {
            margin-top: 0;
            font-size: 20px;
        }
        .signature {
            margin-top: 35px;
        }
    }
}
.about-img {
    position: absolute;
    right: 0;
    top: 70px;
    width: auto !important;
    text-align: right;

    img {
        transition: $transition;
    }
    .about-img1 {
        width: 85%;
    }
    .about-img2 {
        position: absolute;
        left: 70px;
        bottom: -82px;
    }
    .shape-img {
        position: absolute;
        animation: moveleftbounce 3s linear infinite;
        left: 44%;
        top: 9%;
    }
    .uk-button {
        background-color: $main-color;
        color: $white-color;
        position: absolute;
        right: 0;
        bottom: -25px;

        &:hover,
        &:focus {
            background-color: $black-color;
        }
    }
    &:hover,
    &:focus {
        img {
            filter: grayscale(100%);
        }
    }
}
.about-image {
    position: relative;
    text-align: left;
    padding-bottom: 40px;

    img {
        &:nth-child(2) {
            position: absolute;
            right: 20px;
            bottom: 0;
        }
    }
}
@keyframes moveleftbounce {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
    }
}

/*================================================
Services CSS
=================================================*/
.services-area {
    padding-bottom: 40px;

    .item {
        margin-bottom: 30px;
    }
    .pagination-area {
        margin: {
            top: 20px;
            bottom: 30px !important;
        }
    }
}
.single-services {
    position: relative;
    z-index: 1;
    background-color: #f9f9f9;
    padding: 40px 55px 40px 108px;
    transition: $transition;
    border-radius: 65px 0 0 65px;

    .icon {
        position: absolute;
        left: 15px;
        top: 50%;
        z-index: -1;
        width: 75px;
        height: 75px;
        background: $white-color;
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        color: $main-color;
        line-height: 75px;
        margin-top: -38px;
        transition: $transition;

        i::before {
            font-size: 35px;
        }
    }
    h3 {
        font: {
            size: 18px;
            weight: 700;
        }
        margin: 0;
        transition: $transition;

        a {
            color: $black-color;
        }
    }
    .link-btn {
        position: absolute;
        right: 20px;
        top: 50%;
        color: #bdbdbd;
        display: block;
        margin-top: -19px;
        transition: $transition;

        &::before {
            font-size: 25px;
        }
    }
    &:hover,
    &:focus,
    &.active {
        background: $main-color;

        h3,
        a {
            color: $white-color;
        }
        .link-btn {
            right: 10px;
            color: $white-color;
        }
    }
}
.single-services-box {
    background-color: #f7f7ff;
    position: relative;
    z-index: 1;
    padding: 40px;
    transition: $transition;

    .icon {
        color: $main-color;
        transition: $transition;

        i {
            &::before {
                font-size: 45px;
            }
        }
    }
    h3 {
        margin-bottom: 0;
        transition: $transition;
        font: {
            size: 20px;
            weight: 700;
        }
        a {
            color: $black-color;
        }
    }
    .bar {
        position: relative;
        z-index: 1;
        background: $main-color;
        width: 55px;
        height: 3px;
        transition: $transition;
        margin: {
            top: 7px;
            bottom: 20px;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            height: 100%;
            width: 3px;
            top: 0;
            background: $white-color;
            transition: $transition;
        }
        &::after {
            right: 10px;
        }
        &::before {
            right: 20px;
        }
    }
    p {
        margin-bottom: 20px;
        transition: $transition;
    }
    .link-btn {
        display: inline-block;
        position: relative;
        color: $white-color;

        span {
            opacity: 0;
            visibility: hidden;
            transition: $transition;
        }
        i {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: $main-color;
            color: $white-color;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            border-radius: 50%;
            transition: $transition;
        }
        &:hover,
        &:focus {
            span {
                opacity: 1;
                visibility: visible;
                padding-right: 5px;
            }
            i {
                left: 100%;
                width: 25px;
                height: 25px;
                line-height: 23px;

                &::before {
                    font-size: 12px;
                }
            }
        }
    }
    .animation-img {
        img {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            transition: $transition;

            &:nth-child(1) {
                left: 0;
                bottom: 0;
            }
            &:nth-child(2) {
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }
            &:nth-child(3) {
                top: 0;
                left: 0;
            }
            &:nth-child(4) {
                left: 50%;
                transform: translateX(-50%);
                top: 0;
            }
            &:nth-child(5) {
                right: 0;
                top: 0;
            }
            &:nth-child(6) {
                top: 50%;
                transform: translateY(-50%);
                right: 0;
            }
            &:nth-child(7) {
                bottom: 0;
                right: 0;
            }
            &:nth-child(8) {
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
            }
        }
    }
    &:hover,
    &:focus {
        background: $main-color;

        .icon {
            color: $white-color;
        }
        h3 {
            a {
                color: $white-color;
            }
        }
        p {
            color: $white-color;
        }
        .bar {
            background: $white-color;

            &::after,
            &::before {
                background: $main-color;
            }
        }
        .link-btn {
            span {
                opacity: 1;
                visibility: visible;
                padding-right: 7px;
            }
            i {
                left: 100%;
                width: 25px;
                height: 25px;
                line-height: 23px;
                background-color: $white-color;
                color: $main-color;

                &::before {
                    font-size: 12px;
                }
            }
        }
        .animation-img {
            img {
                opacity: 1;
                visibility: visible;

                &:nth-child(1) {
                    left: -25px;
                    bottom: -24px;
                }
                &:nth-child(2) {
                    left: -35px;
                }
                &:nth-child(3) {
                    top: -15px;
                    left: -16px;
                }
                &:nth-child(4) {
                    top: -28px;
                }
                &:nth-child(5) {
                    right: -25px;
                    top: -26px;
                }
                &:nth-child(6) {
                    right: -25px;
                }
                &:nth-child(7) {
                    bottom: -26px;
                    right: -25px;
                }
                &:nth-child(8) {
                    bottom: -25px;
                }
            }
        }
    }
}
.services-section {
    padding-bottom: 40px;

    .section-title {
        h2 {
            max-width: 470px;
            margin: {
                left: auto;
                right: auto;
            }
        }
    }
    &.bg-image {
        position: relative;
        z-index: 1;
        padding-bottom: 140px;

        &::before {
            position: absolute;
            left: 0;
            bottom: 70px;
            height: 50%;
            width: 90%;
            content: "";
            background: {
                image: url(../../assets/img/services-shape.png);
                position: top left;
                size: cover;
                repeat: no-repeat;
            }
        }
    }
}
.services-slides {
    &.owl-theme {
        .owl-dots {
            margin: {
                top: 10px !important;
            }
            .owl-dot {
                outline: 0 !important;

                span {
                    width: 14px;
                    height: 14px;
                    margin: 0 4px;
                    background: transparent;
                    position: relative;
                    transition: $transition;
                    border-radius: 50%;
                    border: 1px solid $white-color;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        transition: $transition;
                        bottom: 0;
                        border-radius: 50%;
                        background-color: $white-color;
                        margin: 3px;
                    }
                }
                &:hover,
                &.active {
                    span {
                        border-color: $main-color;

                        &::before {
                            background-color: $main-color;
                        }
                    }
                }
            }
        }
    }
}
.services-box {
    position: relative;
    text-align: center;
    margin-bottom: 30px !important;
    overflow: hidden;

    img {
        width: 100%;
        border-radius: 5px 5px 0 0;
    }
    .content {
        border-radius: 0 0 5px 5px;
        background-color: $main-color;
        transition: $transition;
        padding: {
            left: 30px;
            right: 30px;
            bottom: 30px;
        }
        .icon {
            border: 2px solid $main-color;
            background-color: $white-color;
            color: $main-color;
            width: 65px;
            height: 65px;
            line-height: 65px;
            border-radius: 50%;
            position: relative;
            top: -30px;
            margin: {
                left: auto;
                right: auto;
                bottom: 20px;
            }
            i {
                &::before {
                    font-size: 30px;
                }
            }
        }
        h3 {
            color: $white-color;
            margin: {
                bottom: 0;
                top: -30px;
            }
            font: {
                size: 22px;
                weight: 700;
            }
        }
    }
    .hover-content {
        border-radius: 5px;
        background-color: $main-color;
        transition: $transition;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 45%;
        opacity: 0;
        visibility: hidden;

        .inner {
            padding: 30px;

            .icon {
                border: 2px solid $main-color;
                background-color: $white-color;
                color: $main-color;
                width: 65px;
                height: 65px;
                line-height: 65px;
                border-radius: 50%;
                margin: {
                    left: auto;
                    right: auto;
                    bottom: 20px;
                }
                i {
                    &::before {
                        font-size: 30px;
                    }
                }
            }
            h3 {
                color: $white-color;
                margin: {
                    bottom: 0;
                }
                font: {
                    size: 22px;
                    weight: 700;
                }
            }
            p {
                color: $white-color;
                margin-top: 12px;
            }
            .details-btn {
                display: inline-block;
                color: $white-color;
                position: relative;
                z-index: 1;

                i {
                    &::before {
                        font-size: 22px;
                    }
                }
                &::before {
                    width: 30px;
                    height: 30px;
                    border: 1px solid $white-color;
                    content: "";
                    position: absolute;
                    left: 5px;
                    top: 1px;
                    border-radius: 50%;
                    z-index: -1;
                    transition: $transition;
                    opacity: 0;
                    visibility: hidden;
                }
                &:hover {
                    color: $main-color;

                    &::before {
                        opacity: 1;
                        visibility: visible;
                        left: 3px;
                        background-color: $white-color;
                    }
                }
            }
        }
    }
    &:hover {
        .content {
            opacity: 0;
            visibility: hidden;
        }
        .hover-content {
            opacity: 1;
            visibility: visible;
            height: 100%;
        }
    }
}

/*================================================
Services Details CSS
=================================================*/
.services-details {
    .uk-width-1-5 {
        width: 33%;
    }
}
.services-details-desc {
    h3 {
        font: {
            size: 24px;
            weight: 700;
        }
        margin: {
            top: 0;
            bottom: 12px;
        }
    }
    p {
        margin-bottom: 15px;
    }
    .services-image-slides {
        margin: {
            bottom: 30px;
            top: 25px;
        }
        &.owl-theme {
            .owl-nav {
                &.disabled {
                    + .owl-dots {
                        position: absolute;
                        right: 0;
                        left: 0;
                        bottom: 15px;

                        .owl-dot {
                            outline: 0 !important;

                            span {
                                width: 16px;
                                height: 16px;
                                margin: 0 4px;
                                outline: 0 !important;
                                background: transparent;
                                transition: $transition;
                                border-radius: 50%;
                                position: relative;
                                padding: 0;
                                text-align: center;
                                border: 1px solid $white-color;

                                &::before {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    margin: 0 auto;
                                    background: $white-color;
                                    transition: $transition;
                                    width: 8px;
                                    height: 8px;
                                    top: 4px;
                                    border-radius: 50%;
                                }
                            }
                            &:hover,
                            &.active {
                                span {
                                    border-color: $main-color;

                                    &::before {
                                        background: $main-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .services-features-list {
        padding: 0;
        margin: 20px 0 10px;
        list-style-type: none;
        overflow: hidden;

        li {
            float: left;
            width: 45%;
            font: {
                size: 15px;
                weight: 500;
            }
            margin-bottom: 10px;

            i {
                color: $main-color;

                &::before {
                    margin-right: 3px;
                }
            }
        }
    }
    blockquote,
    .blockquote {
        background: #f7f7f7;
        padding: 30px 20px;
        margin-bottom: 25px;
        border-left: 4px solid $main-color;

        p {
            margin-bottom: 0;
            color: $black-color;
            font: {
                weight: 600;
                size: 16px;
            }
        }
    }
    .our-work-benefits {
        .accordion {
            list-style-type: none;
            padding: 0;
            margin: 25px 0 0;

            .accordion-item {
                display: block;
                box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
                background: $white-color;
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
            .accordion-title {
                display: block;
                padding: 12px 20px 9px 51px;
                text-decoration: none;
                color: $black-color;
                font: {
                    size: 16px;
                    weight: 500;
                }
                position: relative;
                border-bottom: 1px solid $white-color;

                i {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 40px;
                    text-align: center;
                    height: 100%;
                    background: $main-color;
                    color: $white-color;

                    &::before {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        right: 0;
                        margin-top: -11px;
                    }
                }
                &.active {
                    border-bottom-color: #e1e1e1;

                    i {
                        &::before {
                            content: "\f122";
                        }
                    }
                }
            }
            .accordion-content {
                display: none;
                position: relative;
                padding: 15px 15px;

                &.show {
                    display: block;
                }
            }
        }
    }
}

/*================================================
Project CSS
=================================================*/
.project-area {
    overflow: hidden;

    .pagination-area {
        margin: {
            top: 20px;
        }
    }
}
.project-slides {
    left: calc((100% - 1200px) / 2);
    position: relative;
}
.single-project {
    text-align: center;
    margin-bottom: 30px;

    .project-img {
        img {
            transition: $transition;
        }
    }
    .project-content {
        background-color: #f9f9f9;
        padding: 35px 15px;
        transition: $transition;
        position: relative;
        z-index: 1;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            transition: $transition;
            background: $main-color;
            z-index: -1;
        }
        h3 {
            font: {
                size: 22px;
                weight: 700;
            }
            margin: 0;
            transition: $transition;

            a {
                color: $black-color;

                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
        }
        ul {
            padding: 0;
            margin: 8px 0 0;
            list-style-type: none;

            li {
                display: inline-block;
                position: relative;
                margin: 0 8px;
                transition: $transition;

                &::before {
                    content: "";
                    position: absolute;
                    left: -11px;
                    top: 0;
                    height: 15px;
                    width: 2px;
                    background: $paragraph-color;
                    top: 3px;
                    transform: rotate(10deg);
                    transition: $transition;
                }
                &:first-child {
                    &::before {
                        display: none;
                    }
                }
                a {
                    color: $paragraph-color;

                    &:hover,
                    &:focus {
                        color: $main-color;
                    }
                }
            }
        }
    }
    &:hover,
    &:focus {
        .project-content {
            &::before {
                height: 100%;
            }
            h3 {
                a {
                    color: $white-color;
                }
            }
            ul {
                li {
                    a {
                        color: $white-color;
                    }
                    &::before {
                        background: $white-color;
                    }
                }
            }
        }
        .project-img {
            img {
                filter: grayscale(100%);
            }
        }
    }
}
.project-slides {
    &.owl-theme {
        .owl-dots {
            line-height: 0.01;
            margin-top: 20px !important;

            .owl-dot {
                span {
                    width: 15px;
                    height: 5px;
                    margin: 0 3px;
                    background: #d6d6d6;
                    transition: $transition;
                    border-radius: 0;
                }
                &.active,
                &:hover,
                &:focus {
                    outline: 0 !important;
                    box-shadow: unset !important;

                    span {
                        background: $main-color;
                    }
                }
                &.active {
                    span {
                        width: 22px;
                    }
                }
            }
        }
    }
}

// TICKET SECTION START
// TICKET SECTION START
.ticket-section{
    padding: 8rem 3rem 8rem 3rem;
}


.ticket-section .wrapper-ticket .text-ticket {
    // display: flexbpx;
    justify-content: center;
    align-items: center;
}
.ticket-section .wrapper-ticket .text-ticket p{
    text-align: justify;
}
.ticket-section .wrapper-ticket .image-ticket {
    padding-bottom: 5rem;
}
.ticket-section .wrapper-ticket .image-ticket img{
    border-radius: 100px;
    border: solid 0.3rem #ff4800;
}

.ticket-section .wrapper-frame iframe{
    border-radius: 10px;
    margin-top: 2rem;
    // filter: drop-shadow( 1rem 1rem 25px #ff4800);
    border: solid 0.3rem #ff4800;
}
// TICKET SECTION END
// TICKET SECTION END

.project-section {
    position: relative;
    z-index: 1;
    padding-bottom: 40px;
}
.single-project-box {
    text-align: center;
    margin-bottom: 30px !important;

    .project-img {
        img {
            transition: $transition;
        }
    }
    .project-content {
        background-color: #f9f9f9;
        padding: 30px;
        transition: $transition;
        text-align: left;
        position: relative;
        z-index: 1;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            transition: $transition;
            background: $main-color;
            z-index: -1;
        }
        h3 {
            margin: 0;
            transition: $transition;
            font: {
                size: 22px;
                weight: 700;
            }
            a {
                color: $black-color;

                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
        }
        ul {
            padding: 0;
            margin: 8px 0 0;
            list-style-type: none;

            li {
                display: inline-block;
                position: relative;
                margin: 0 8px;
                transition: $transition;

                &::before {
                    content: "";
                    position: absolute;
                    left: -9px;
                    top: 0;
                    height: 14px;
                    width: 1px;
                    background: $paragraph-color;
                    top: 4px;
                    transform: rotate(10deg);
                    transition: $transition;
                }
                &:first-child {
                    &::before {
                        display: none;
                    }
                }
                a {
                    color: $paragraph-color;

                    &:hover,
                    &:focus {
                        color: $main-color;
                    }
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
        .details-btn {
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            width: 45px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            transition: $transition;
            border-radius: 50%;
            background-color: $main-color;
            color: $white-color;

            i {
                svg {
                    width: 28px;
                }
            }
            &:hover {
                color: $white-color !important;
                background-color: $black-color !important;
            }
        }
    }
    &:hover,
    &:focus {
        .project-content {
            &::before {
                height: 100%;
            }
            h3 {
                a {
                    color: $white-color;
                }
            }
            ul {
                li {
                    a {
                        color: $white-color;
                    }
                    &::before {
                        background: $white-color;
                    }
                }
            }
            .details-btn {
                background-color: $white-color;
                color: $main-color;
            }
        }
        .project-img {
            img {
                filter: grayscale(100%);
            }
        }
    }
}

/*================================================
Project Details CSS
=================================================*/
.project-details {
    .uk-width-1-5 {
        width: 33%;
    }
}
.project-details-info {
    background: $white-color;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    padding: 30px;

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            color: $paragraph-color;
            font-size: 16px;
            margin-bottom: 14px;

            span {
                font-weight: 500;
                display: inline-block;
                margin: {
                    right: 5px;
                }
                color: $black-color;
            }
            a {
                display: inline-block;
                color: $paragraph-color;

                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            ul {
                display: inline-block;

                li {
                    display: inline-block;
                    margin-bottom: 0;
                    margin-right: 5px;

                    a {
                        width: 35px;
                        height: 35px;
                        background: #2d3046;
                        text-align: center;
                        color: $white-color;
                        line-height: 35px;
                        border-radius: 50%;

                        i {
                            &::before {
                                font-size: 14px;
                            }
                        }
                        &:hover,
                        &:focus {
                            background: $main-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
}
.project-details-desc {
    margin-top: 30px;

    h3 {
        font: {
            size: 24px;
            weight: 700;
        }
        margin: {
            top: 0;
            bottom: 12px;
        }
    }
    p {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }
    blockquote,
    .blockquote {
        background: #f7f7f7;
        padding: 30px 20px;
        margin-bottom: 25px;
        border-left: 4px solid $main-color;

        p {
            margin-bottom: 0;
            color: $black-color;
            font: {
                weight: 600;
                size: 15px;
            }
        }
    }
}
.project-next-and-prev {
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    background: $white-color;
    margin-top: 30px;
    position: relative;
    padding: 30px;

    .uk-button {
        flex: unset;
        width: auto;
    }
    .uk-text-right {
        display: block !important;
    }
}

/*================================================
Feedback CSS
=================================================*/
.feedback-img {
    position: relative;

    img {
        transition: $transition;
    }
    .shape-img {
        position: absolute;
        left: 20px;
        top: -55px;
        animation: moveleftbounce 3s linear infinite;
    }
    .video-btn {
        position: absolute;
        right: 15px;
        bottom: 200px;
        background: $main-color;
        color: $white-color;
        padding: 20px 20px 20px 65px;
        border-radius: 45px;

        i {
            position: absolute;
            left: 10px;
            top: 50%;
            width: 45px;
            height: 45px;
            display: inline-block;
            text-align: center;
            line-height: 45px;
            background: $white-color;
            color: $main-color;
            border-radius: 50%;
            margin-top: -22px;
            transition: $transition;
        }
        &:hover,
        &:focus {
            background: $white-color;
            color: $main-color;

            i {
                background: $main-color;
                color: $white-color;
            }
        }
    }
    &:hover,
    &:focus {
        img {
            filter: grayscale(100%);
        }
    }
}
.single-feedback {
    i {
        color: #e7e7e7;
        line-height: 45px;

        &::before {
            font-size: 65px;
        }
    }
    p {
        font: {
            size: 16px;
            style: italic;
        }
        margin-bottom: 0;
    }
    .client {
        margin-top: 20px;

        h3 {
            font: {
                size: 18px;
                weight: 600;
            }
            margin: 0;
        }
        span {
            display: block;
            color: $main-color;
            margin-top: 6px;
            font-size: 14px;
        }
    }
}
.feedback-inner {
    margin-top: 75px;
}
.feedback-slides {
    &.owl-theme {
        .owl-dots {
            line-height: 0.01;
            position: absolute;
            right: 0;
            left: 0;
            margin: 0 auto;
            z-index: -1;
            bottom: -40px;

            .owl-dot {
                span {
                    width: 40px;
                    height: 6px;
                    margin: 0;
                    background: #d6d6d6;
                    transition: $transition;
                    border-radius: 0;
                }
                &.active,
                &:hover,
                &:focus {
                    outline: 0 !important;
                    box-shadow: unset !important;

                    span {
                        background: $main-color;
                    }
                }
            }
        }
        .owl-nav {
            margin-top: 0;
            line-height: 0.01;

            [class*="owl-"] {
                color: $black-color;
                font-size: unset;
                margin: 0;
                padding: 0;
                background: transparent;
                display: inline-block;
                cursor: pointer;
                border-radius: 0;
                position: absolute;
                left: 0;
                transition: $transition;
                height: 20px;
                bottom: -47px;
                z-index: 10;

                i {
                    &::before {
                        font-size: 20px;
                    }
                }
                &.owl-next {
                    left: auto;
                    right: 0;
                }
                &:hover,
                &:focus {
                    outline: 0 !important;
                    box-shadow: unset !important;
                    color: $main-color;
                }
            }
        }
    }
}
.testimonials-area {
    padding-bottom: 40px;
}
.testimonials-item {
    margin-bottom: 30px;

    .testimonials-single-item {
        position: relative;
        box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
        background-color: $white-color;
        padding: 25px;

        p {
            font-style: italic;
            margin-bottom: 0;
            padding-left: 40px;
            position: relative;

            &::before {
                position: absolute;
                content: "\f117";
                color: #777777;
                font-family: Flaticon;
                left: 0;
                top: -5px;
                font-size: 25px;
            }
        }
        &::before {
            border-left: 0 solid transparent;
            border-right: 23px solid transparent;
            border-top: 32px solid $white-color;
            bottom: -32px;
            content: "";
            height: 0;
            left: 50px;
            position: absolute;
            width: 0;
        }
    }
    .quotation-profile {
        position: relative;
        margin: {
            top: 40px;
            left: 10px;
        }
        img {
            width: 70px;
            box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
            border: 2px solid $white-color;
            border-radius: 50%;
            height: 70px;
        }
        .profile-info {
            position: absolute;
            top: -5px;
            left: 90px;

            h3 {
                font: {
                    size: 18px;
                    weight: 600;
                }
                margin-bottom: 6px;
            }
            span {
                display: block;
                font-size: 14px;
                color: $main-color;
            }
        }
    }
}
.feedback-section {
    padding-bottom: 40px;

    .section-title {
        h2 {
            max-width: 500px;
            margin: {
                left: auto;
                right: auto;
            }
        }
    }
}
.single-feedback-item {
    margin-bottom: 30px !important;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: #fcf9f9;
    border-radius: 5px;
    padding: {
        top: 50px;
        bottom: 50px;
        left: 30px;
        right: 30px;
    }
    i {
        color: #e7e7e7;
        display: block;
        margin-top: -25px;
        transition: $transition;

        &::before {
            font-size: 65px;
        }
    }
    p {
        margin-bottom: 0;
        color: #666666;
        transition: $transition;
        font: {
            size: 15px;
            style: italic;
        }
    }
    .client-info {
        text-align: left;
        position: relative;
        transition: $transition;
        padding-left: 65px;
        max-width: 160px;
        margin: {
            top: 20px;
            left: auto;
            right: auto;
        }
        img {
            width: 50px;
            border-radius: 50%;
            border: 2px solid $main-color;
            position: absolute;
            left: 0;
            top: 0;
            transition: $transition;
        }
        h3 {
            margin-bottom: 3px;
            transition: $transition;
            font: {
                size: 20px;
                weight: 600;
            }
        }
        span {
            display: block;
            font-size: 14px;
            color: $main-color;
            transition: $transition;
        }
    }
    &::before {
        content: "";
        position: absolute;
        left: -69%;
        top: -95%;
        width: 240%;
        height: 100%;
        background-color: $main-color;
        z-index: -1;
        border-radius: 50%;
        transition: $transition;
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        transition: $transition;
        height: 5%;
        background-color: $main-color;
        z-index: -1;
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
    }
    &:hover {
        i {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        .client-info {
            img {
                border-color: $white-color;
            }
            h3 {
                color: $white-color;
            }
            span {
                color: $white-color;
            }
        }
        &::before {
            opacity: 0;
            visibility: hidden;
        }
        &::after {
            opacity: 1;
            visibility: visible;
            height: 100%;
        }
    }
}
.feedback-section {
    .uk-grid {
        .uk-section-title {
            margin: {
                top: 130px;
                bottom: 0 !important;
            }
            h2 {
                max-width: 100%;
                margin: {
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}
.feedback-slides-two {
    margin-bottom: 70px !important;

    &.owl-theme {
        .owl-dots {
            line-height: 0.01;
            position: absolute;
            right: 0;
            left: 0;
            margin: 0 auto;
            z-index: -1;
            bottom: -40px;

            .owl-dot {
                span {
                    width: 40px;
                    height: 6px;
                    margin: 0;
                    background: #d6d6d6;
                    transition: $transition;
                    border-radius: 0;
                }
                &.active,
                &:hover,
                &:focus {
                    outline: 0 !important;
                    box-shadow: unset !important;

                    span {
                        background: $main-color;
                    }
                }
            }
        }
        .owl-nav {
            margin-top: 0;
            line-height: 0.01;

            [class*="owl-"] {
                color: $black-color;
                font-size: unset;
                margin: 0;
                padding: 0;
                background: transparent;
                display: inline-block;
                cursor: pointer;
                border-radius: 0;
                position: absolute;
                left: 0;
                transition: $transition;
                height: 20px;
                bottom: -47px;
                z-index: 10;

                i {
                    &::before {
                        font-size: 20px;
                    }
                }
                &.owl-next {
                    left: auto;
                    right: 0;
                }
                &:hover,
                &:focus {
                    outline: 0 !important;
                    box-shadow: unset !important;
                    color: $main-color;
                }
            }
        }
    }
}

/*================================================
Why Choose Us CSS
=================================================*/
.why-choose-us-content {
    margin-left: auto;
    max-width: 585px;

    .section-title {
        margin-bottom: 40px !important;
    }
    .why-choose-us-text {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 0;
            bottom: 0;
        }
        li {
            display: block;
            position: relative;
            margin-bottom: 20px;
            padding-left: 90px;

            .icon {
                border: 2.5px solid #c4e2da;
                background-color: $main-color;
                background: linear-gradient(
                    to top,
                    #ee3e0c,
                    #f25e00,
                    #f57900,
                    #f79100,
                    #f8a815
                );
                color: $white-color;
                width: 65px;
                text-align: center;
                height: 65px;
                line-height: 65px;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 0;

                i {
                    &::before {
                        font-size: 30px;
                    }
                }
            }
            h3 {
                font: {
                    size: 19px;
                    weight: 700;
                }
                margin: {
                    top: 0;
                    bottom: 7px;
                }
            }
            p {
                margin-bottom: 0;
                font-size: 14.5px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &:nth-child(2) {
                .icon {
                    background: linear-gradient(
                        to top,
                        #1a24ff,
                        #2d36ff,
                        #3b44ff,
                        #4851ff,
                        #545dff
                    );
                }
            }
            &:nth-child(3) {
                .icon {
                    background: linear-gradient(
                        to top,
                        #c90068,
                        #ad005a,
                        #91004c,
                        #76003f,
                        #5d0031
                    );
                }
            }
        }
    }
}

/*================================================
Partner CSS
=================================================*/
.partner-area {
    border-bottom: 1px solid #f0f0f0;

    .item {
        text-align: center;

        a {
            img {
                display: inline-block !important;
                width: 140px !important;
                transition: $transition;
                opacity: 0.45;
            }
            &:hover,
            &:focus {
                img {
                    opacity: 1;
                    animation: toTopFromBottom 0.5s forwards;
                }
            }
        }
    }
}
@keyframes toTopFromBottom {
    49% {
        transform: translateY(50%);
    }
    50% {
        opacity: 0;
        transform: translateY(-50%);
    }
    51% {
        opacity: 1;
    }
}

/*================================================
Team CSS
=================================================*/
.team-area {
    padding-bottom: 40px;
}
.single-team {
    text-align: center;
    position: relative;
    transition: $transition;
    margin-bottom: 30px;

    .team-social {
        padding: 0;
        margin: 0 auto;
        list-style-type: none;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 35px;
        opacity: 0;
        visibility: hidden;
        transition: $transition;

        li {
            display: inline-block;
            margin: 0 3px;

            a {
                width: 35px;
                height: 35px;
                line-height: 33px;
                color: $main-color;
                background: transparent;
                border-radius: 50%;
                border: 1px solid $main-color;

                &:hover,
                &:focus {
                    background: $main-color;
                    color: $white-color;
                }
                i {
                    &::before {
                        font-size: 13px;
                    }
                }
            }
        }
    }
    img {
        transition: all 700ms ease-out;
        position: relative;
        top: 0;
    }
    .team-content {
        padding-top: 20px;
        transition: $transition;
        position: relative;
        background: $white-color;

        h3 {
            font: {
                size: 20px;
                weight: 700;
            }
            margin: 0;
        }
        span {
            display: block;
            color: $paragraph-color;
            margin-top: 5px;
        }
    }
    &:hover,
    &:focus {
        .team-social {
            opacity: 1;
            top: 18px;
            visibility: visible;
        }
        img {
            top: 70px;
            filter: grayscale(100%);
        }
    }
}
.team-slides {
    &.owl-theme {
        .owl-dots {
            line-height: 0.01;
            margin: {
                top: 20px !important;
                bottom: 30px;
            }
            .owl-dot {
                span {
                    width: 15px;
                    height: 5px;
                    margin: 0 3px;
                    background: #d6d6d6;
                    transition: $transition;
                    border-radius: 0;
                }
                &.active,
                &:hover,
                &:focus {
                    outline: 0 !important;
                    box-shadow: unset !important;

                    span {
                        background: $main-color;
                    }
                }
                &.active {
                    span {
                        width: 22px;
                    }
                }
            }
        }
    }
}
.team-area {
    .uk-section-title {
        &.uk-text-center {
            h2 {
                max-width: 539px;
                margin: {
                    left: auto;
                    right: auto;
                }
            }
        }
    }
}
.single-team-box {
    margin-bottom: 30px !important;

    .content {
        background-color: #f9f9f9;
        padding: 30px;
        position: relative;

        h3 {
            font: {
                size: 22px;
                weight: 700;
            }
            margin: {
                top: 0;
                bottom: 0;
            }
        }
        span {
            display: block;
            color: $paragraph-color;
            margin-top: 6px;
        }
        .social {
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);

            .social-btn {
                span {
                    display: inline-block;
                    width: 45px;
                    height: 45px;
                    cursor: pointer;
                    line-height: 42px;
                    border-radius: 50%;
                    margin-top: 0;
                    background-color: $main-color;
                    color: $white-color;
                    text-align: center;
                    font-size: 22px;
                    transition: $transition;

                    svg {
                        width: 22px;
                    }
                    &:hover {
                        background-color: $black-color;
                        color: $white-color;
                    }
                }
            }
            ul {
                padding-left: 0;
                list-style-type: none;
                position: absolute;
                transition: $transition;
                left: 50%;
                bottom: 55px;
                transform: translateX(-50%);
                opacity: 0;
                visibility: hidden;
                margin: {
                    top: 0;
                    bottom: 15px;
                }
                li {
                    display: block;
                    margin: {
                        bottom: 5px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        display: block;
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        background-color: $white-color;
                        color: $main-color;
                        text-align: center;
                        border-radius: 50%;

                        &:hover {
                            color: $white-color;
                            background-color: $main-color;
                        }
                    }
                }
            }
            &:hover {
                ul {
                    opacity: 1;
                    visibility: visible;
                    margin-bottom: 0;
                }
            }
        }
    }
}

/*================================================
Subscribe CSS
=================================================*/
.subscribe-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    h3 {
        font: {
            size: 26px;
            weight: 700;
        }
        margin: 8px 0 0;
        text-transform: uppercase;
    }
    form {
        position: relative;

        .uk-input {
            height: 50px;
            border: none;
            padding-left: 20px;
        }
        .uk-button {
            position: absolute;
            right: 0;
            top: 0;
            height: 50px;
            background-color: $main-color;
            color: $white-color;

            &:hover,
            &:focus {
                background-color: $black-color;
            }
        }
        .validation-danger {
            color: red;
            margin-top: 15px;
        }
        .validation-success {
            color: $paragraph-color;
            margin-top: 15px;
        }
    }
    .shape {
        position: absolute;
        left: 20%;
        bottom: -70px;
        z-index: -1;
        transform: rotate(-132deg);
    }
}

/*================================================
Blog CSS
=================================================*/
.single-blog-post {
    margin-bottom: 30px;

    .blog-post-image {
        img {
            transition: $transition;
        }
    }
    .blog-post-content {
        border: 1px solid #f4f4f4;
        background-color: $white-color;
        margin: {
            right: 20px;
            left: 20px;
            top: -30px;
        }
        position: relative;
        z-index: 1;
        padding: 20px;
        transition: $transition;

        span {
            display: inline-block;
            color: $white-color;
            background-color: $main-color;
            padding: 4px 12px;
        }
        h3 {
            font: {
                size: 22px;
                weight: 700;
            }
            margin: 15px 0 15px;

            a {
                color: $black-color;

                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
        }
        .read-more {
            text-transform: uppercase;
            color: $main-color;

            &:hover,
            &:focus {
                letter-spacing: 1px;
            }
        }
    }
    &:hover,
    &:focus {
        .blog-post-image {
            img {
                filter: grayscale(100%);
            }
        }
        .blog-post-content {
            margin-top: -40px;
        }
    }
}
.blog-slides {
    &.owl-theme {
        .owl-dots {
            line-height: 0.01;
            margin-top: 20px !important;

            .owl-dot {
                span {
                    width: 15px;
                    height: 5px;
                    margin: 0 3px;
                    background: #d6d6d6;
                    transition: $transition;
                    border-radius: 0;
                }
                &.active,
                &:hover,
                &:focus {
                    outline: 0 !important;
                    box-shadow: unset !important;

                    span {
                        background: $main-color;
                    }
                }
                &.active {
                    span {
                        width: 22px;
                    }
                }
            }
        }
    }
}

/*================================================
Blog Details CSS
=================================================*/
.blog-details {
    .uk-width-1-5 {
        width: 33%;
    }
}
.blog-details {
    .inner {
        .article-img {
            position: relative;

            .date {
                position: absolute;
                bottom: 15px;
                width: 100px;
                left: 15px;
                height: 70px;
                text-align: center;
                font: {
                    size: 20px;
                    weight: 600;
                    style: italic;
                }
                background: $main-color;
                color: $white-color;
                border-radius: 5px;
                padding-top: 10px;
                line-height: 28px;
            }
        }
        .article-content {
            margin-top: 30px;

            ul {
                &.category {
                    padding: 0;
                    list-style-type: none;
                    margin: 0;

                    li {
                        display: inline-block;
                        margin-right: 8px;

                        a {
                            background: $main-color;
                            color: $white-color;
                            padding: 5px 15px;
                            display: inline-block;
                            border-radius: 3px;
                        }
                    }
                    margin-bottom: 25px;
                }
            }
            h3 {
                font: {
                    size: 24px;
                    weight: 700;
                }
                margin: 0;
                line-height: 30px;
            }
            p {
                margin: {
                    bottom: 0;
                    top: 15px;
                }
            }
            .blockquote {
                margin: {
                    bottom: 20px;
                    top: 20px;
                }
                background: #f7f7f7;
                padding: 30px;
                border-left: 4px solid $main-color;

                p {
                    margin-top: 0;
                    font-size: 18px;
                }
            }
        }
        .post-controls-buttons {
            margin-top: 30px;

            .uk-button {
                flex: unset;
                width: auto;
            }
            .uk-text-right {
                display: block !important;
            }
        }
        .comments-area {
            margin-top: 45px;

            .comments-title,
            .comment-reply-title {
                font: {
                    size: 24px;
                    weight: 700;
                }
                margin-bottom: 25px;
            }
            .comment-reply-title {
                margin-bottom: 8px;
            }
            ol,
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    ol,
                    ul {
                        li {
                            margin: {
                                top: 15px;
                                left: 35px;
                                bottom: 15px;
                            }
                        }
                    }
                    .comment-body {
                        background: #f7f7ff;
                        padding: 25px 20px;
                        position: relative;

                        .comment-meta {
                            position: relative;
                            padding: {
                                left: 80px;
                                top: 8px;
                            }
                            .comment-author {
                                img {
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    border-radius: 50%;
                                }
                                .fn {
                                    font: {
                                        size: 16px;
                                        weight: 600;
                                    }
                                    margin-bottom: 5px;
                                    color: $black-color;
                                    display: block;
                                }
                                .says {
                                    display: none;
                                }
                            }
                            .comment-metadata {
                                a {
                                    display: inline-block;
                                    color: $paragraph-color;
                                    font-size: 13px;

                                    &:hover,
                                    &:focus {
                                        color: $main-color;
                                    }
                                }
                            }
                        }
                        .comment-content {
                            margin: {
                                top: 20px;
                            }
                        }
                        .reply {
                            position: absolute;
                            right: 30px;
                            top: 30px;

                            a {
                                display: inline-block;
                                background: $black-color;
                                padding: 5px 15px;
                                color: $white-color;

                                &:hover,
                                &:focus {
                                    background: $main-color;
                                }
                            }
                        }
                    }
                }
            }
            .comment-respond {
                margin-top: 30px;

                .comment-notes {
                    font-size: 14px;
                    margin-bottom: 20px;
                }
                .comment-form-comment,
                .comment-form-author,
                .comment-form-email,
                .comment-form-url {
                    input,
                    textarea {
                        display: block;
                        width: 100%;
                        height: 45px;
                        outline: 0 !important;
                        box-shadow: unset !important;
                        border: 1px solid #eeeeee;
                        padding: 0 15px;
                        border-radius: 3px;
                        font-size: 15px;
                        transition: $transition;

                        &:focus {
                            border-color: $main-color;
                        }
                    }
                    textarea {
                        height: auto;
                        padding-top: 15px;
                    }
                }
                .form-submit {
                    input {
                        display: inline-block;
                        background: $main-color;
                        border: none;
                        color: $white-color;
                        padding: 16px 30px;
                        text-transform: uppercase;
                        font-weight: 600;
                        outline: 0 !important;
                        cursor: pointer;
                        margin-top: 5px;
                        transition: $transition;

                        &:hover,
                        &:focus {
                            background: $black-color;
                            box-shadow: 0 13px 27px 0 rgba(255, 82, 2, 0.11);
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Contact CSS
=================================================*/
.contact-area {
    padding-bottom: 100px;

    .section-title {
        text-align: center;

        h2 {
            margin-bottom: 0;
        }
    }
}
.map-img {
    position: relative;
    z-index: 1;

    .location {
        position: absolute;
        left: 24%;
        top: 30%;
        width: auto;
        height: auto;

        a {
            width: 14px;
            height: 14px;
            background: $main-color;
            border-radius: 50%;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: -4px;
                bottom: -4px;
                border: 4px solid #eec3b3;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                animation-name: ripple;
                animation-duration: 2s;
                animation-delay: 0s;
                animation-iteration-count: infinite;
                animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
            }
            .location-info {
                position: absolute;
                left: -55px;
                bottom: 30px;
                transition: $transition;
                width: 160px;
                background: $white-color;
                text-align: center;
                padding: 20px 15px;
                border-radius: 3px;
                z-index: 1;
                box-shadow: 0 -2px 20px 0 rgba(0, 0, 0, 0.06);
                opacity: 0;
                visibility: hidden;

                h5 {
                    font: {
                        size: 16px;
                        weight: 700;
                    }
                    margin: 0;
                    color: #616161;
                }
                span {
                    display: block;
                    font-size: 14px;
                    color: #616161;
                    margin-top: 6px;
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 50px;
                    bottom: -10px;
                    width: 25px;
                    height: 25px;
                    background: $white-color;
                    z-index: -1;
                    transform: rotate(45deg);
                    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.06);
                }
            }
            &:hover,
            &:focus,
            &.active {
                .location-info {
                    opacity: 1;
                    visibility: visible;
                    bottom: 40px;
                }
            }
        }
        &.uk-location2 {
            right: 22%;
            left: auto;
            top: 25%;
        }
    }
}
#contactForm {
    .uk-grid-medium {
        margin-left: -15px;
    }
    .uk-margin {
        margin: {
            top: 0 !important;
            bottom: 15px;
        }
        padding-left: 15px;
    }
    .uk-input {
        height: 55px;
        background-color: #f4f4f4;
        border: none;
        padding-left: 15px;

        &::placeholder {
            color: #8a8a8a;
        }
        &:focus {
            padding-left: 17px;
        }
    }
    .uk-textarea {
        background-color: #f4f4f4;
        border: none;
        padding: {
            left: 15px;
            top: 15px;
        }
        &::placeholder {
            color: #8a8a8a;
        }
    }
    .uk-button {
        background-color: $main-color;
        color: $white-color;
        margin-top: 20px;

        &:hover,
        &:focus {
            background-color: $black-color;
        }
    }
}
.contact-section {
    overflow: hidden;
}
.contact-image {
    position: relative;
    background: {
        image: url(../../assets/img/contact-image.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    img {
        display: none;
    }
    .contact-info {
        position: absolute;
        right: -50px;
        bottom: 133px;
        max-width: 380px;
        background-color: $main-color;
        border-radius: 5px;
        padding: 30px;

        h3 {
            color: $white-color;
            margin: {
                bottom: 20px;
                top: 0;
            }
            font: {
                size: 28px;
                weight: 700;
            }
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                display: block;
                margin-bottom: 12px;
                color: $white-color;

                a {
                    display: inline-block;
                    color: $white-color;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.contact-form {
    max-width: 600px;
    padding: {
        top: 70px;
        bottom: 70px;
        left: 70px;
    }
    .section-title {
        max-width: 315px;
    }
}
@keyframes ripple {
    from {
        opacity: 1;
        transform: scale3d(0.75, 0.75, 1);
    }
    to {
        opacity: 0;
        transform: scale3d(1.5, 1.5, 1);
    }
}

/*================================================
Process CSS
=================================================*/
.process-section {
    overflow: hidden;
    position: relative;
    z-index: 1;

    .section-title {
        h2 {
            max-width: 470px;
            margin: {
                left: auto;
                right: auto;
            }
        }
    }
    .item {
        &:nth-child(1),
        &:nth-child(3) {
            .single-process-box {
                margin-top: 30px !important;
            }
        }
    }
    .uk-grid {
        position: relative;
        z-index: 1;
    }
}
.single-process-box {
    text-align: center;
    position: relative;
    z-index: 2;

    .icon {
        border: 4px solid #eeeeee;
        width: 75px;
        height: 75px;
        text-align: center;
        color: $white-color;
        line-height: 70px;
        border-radius: 50%;
        background-color: $main-color;
        margin: {
            left: auto;
            right: auto;
            bottom: 20px;
        }
        i {
            svg {
                width: 35px;
            }
        }
    }
    h3 {
        margin: 0;
        font: {
            size: 22px;
            weight: 700;
        }
    }
    p {
        margin-top: 7px;
    }
}
.process-arrow-icon {
    position: absolute;
    left: 150px;
    z-index: -1;
    top: -15px;
}
.process-section {
    &.bg-image {
        &::before {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 70%;
            width: 100%;
            opacity: 0.85;
            content: "";
            background: {
                image: url(../../assets/img/work-process-shape.png);
                position: center center;
                size: cover;
                repeat: no-repeat;
            }
        }
    }
}
.item {
    &:nth-child(1) {
        .single-process-box {
            .icon {
                border-color: #d6d8d7;
                background: linear-gradient(
                    to bottom,
                    #d89287,
                    #e08071,
                    #e66c5a,
                    #ea5743,
                    #ec3d2a
                );
            }
        }
    }
    &:nth-child(2) {
        .single-process-box {
            .icon {
                border-color: #d6d8d7;
                background: linear-gradient(
                    to bottom,
                    #78cfc6,
                    #66c8c0,
                    #51c1ba,
                    #37bab4,
                    #00b3af
                );
            }
        }
    }
    &:nth-child(3) {
        .single-process-box {
            .icon {
                border-color: #d6d8d7;
                background: linear-gradient(
                    to top,
                    #ee3e0c,
                    #f25e00,
                    #f57900,
                    #f79100,
                    #f8a815
                );
            }
        }
    }
    &:nth-child(4) {
        .single-process-box {
            .icon {
                border-color: #d6d8d7;
                background: linear-gradient(
                    to top,
                    #a830f1,
                    #b02ef3,
                    #b82cf5,
                    #bf2af7,
                    #c727f9
                );
            }
        }
    }
}

/*================================================
FunFacts CSS
=================================================*/
.funfacts-area {
    z-index: 1;
    position: relative;
    background: {
        image: url(../../assets/img/funfacts-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
}
.single-funfacts {
    position: relative;
    padding-left: 100px;

    .icon {
        border: 3px solid #c0bebd;
        width: 75px;
        height: 75px;
        text-align: center;
        position: absolute;
        left: 0;
        top: 2px;
        color: $main-color;
        line-height: 70px;
        border-radius: 50%;
        background-color: $white-color;

        i {
            svg {
                width: 35px;
            }
        }
    }
    h3 {
        color: $white-color;
        margin: {
            bottom: -2px;
            top: 0;
        }
        font: {
            weight: 600;
            size: 40px;
        }
        .odometer-formatting-mark {
            display: none;
        }
    }
    p {
        color: $main-color;
        margin-bottom: 0;
        line-height: initial;
        font: {
            size: 16px;
            weight: 500;
        }
    }
}

/*================================================
Page Title CSS
=================================================*/
.page-title-area {
    padding: {
        top: 200px;
        bottom: 125px;
    }
    text-align: center;
    z-index: 1;
    position: relative;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
        attachment: fixed;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background: $black-color;
        opacity: 0.77;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    h1 {
        font: {
            size: 34px;
            weight: 700;
        }
        color: $white-color;
        position: relative;
        margin: 0;
    }
    ul {
        padding: 0;
        margin: 20px 0 0;
        list-style-type: none;

        li {
            display: inline-block;
            margin-right: 20px;
            color: $main-color;
            position: relative;

            a {
                display: block;
                color: $white-color;

                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
            &::before {
                content: "";
                position: absolute;
                left: -10px;
                top: 4px;
                width: 2px;
                height: 14px;
                background: $white-color;
                transform: rotate(11deg);
            }
            &:first-child {
                &::before {
                    display: none;
                }
            }
        }
    }
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
    text-align: center;
    margin-top: 30px;

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            a {
                font: {
                    weight: 600;
                    size: 16px;
                }

                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
            &.uk-active {
                a {
                    color: $main-color;
                }
            }
        }
    }
}

/*================================================
Sidebar CSS
=================================================*/
.uk-sidebar {
    .widget {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
        .widget-title {
            font: {
                size: 24px;
                weight: 700;
            }
            margin: {
                bottom: 0;
                top: 0;
            }
            position: relative;
        }
        .bar {
            position: relative;
            z-index: 1;
            background: $main-color;
            width: 55px;
            height: 3px;
            margin: {
                top: 8px;
                bottom: 25px;
            }
            transition: $transition;

            &::before,
            &::after {
                content: "";
                position: absolute;
                height: 100%;
                width: 3px;
                top: 0;
                background: $white-color;
                transition: $transition;
            }
            &::after {
                right: 10px;
            }
            &::before {
                right: 20px;
            }
        }
        &:hover,
        &:focus {
            .widget-title {
                &::before {
                    transform: rotate(180deg);
                }
            }
        }
        &.service_list {
            background: $white-color;
            box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);

            ul {
                padding: 0;
                list-style-type: none;
                margin: 0;

                li {
                    a {
                        display: block;
                        position: relative;
                        border-bottom: 1px solid #eeeeee;
                        padding: {
                            top: 15px;
                            bottom: 15px;
                            left: 15px;
                            right: 35px;
                        }
                        color: #5d6576;
                        font-weight: 500;
                        z-index: 1;

                        &:hover,
                        &:focus,
                        &.active {
                            background: $main-color;
                            color: $white-color;
                            padding: {
                                left: 20px;
                            }
                            &::before {
                                width: 5px;
                            }
                        }
                        i {
                            position: absolute;
                            right: 15px;
                            top: 50%;
                            margin-top: -10px;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 0;
                            height: 100%;
                            background: $black-color;
                            transition: $transition;
                        }
                    }
                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
        &.widget_download {
            ul {
                padding: 0;
                list-style-type: none;
                padding: 0;
                background: $white-color;
                box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);

                li {
                    a {
                        display: block;
                        position: relative;
                        border-bottom: 1px solid #eeeeee;
                        padding: {
                            top: 15px;
                            bottom: 15px;
                            left: 15px;
                            right: 35px;
                        }
                        color: #5d6576;
                        font-weight: 500;
                        z-index: 1;

                        &:hover,
                        &:focus,
                        &.active {
                            background: $black-color;
                            color: $white-color;
                            padding: {
                                left: 20px;
                            }
                            &::before {
                                width: 5px;
                            }
                        }
                        i {
                            position: absolute;
                            right: 15px;
                            top: 50%;
                            margin-top: -10px;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 0;
                            height: 100%;
                            background: $main-color;
                            transition: $transition;
                        }
                    }
                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
        &.widget_contact {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    padding-left: 23px;
                    position: relative;
                    margin-bottom: 12px;
                    color: $paragraph-color;

                    a {
                        color: $paragraph-color;
                        display: block;

                        &:hover,
                        &:focus {
                            color: $main-color;
                        }
                    }
                    i {
                        color: $main-color;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &.widget_search {
            background: $white-color;
            box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
            padding: 15px;

            form {
                position: relative;

                .form-control {
                    background: transparent;
                }
                button {
                    position: absolute;
                    right: 5px;
                    top: 0;
                    height: 100%;
                    border: none;
                    background: transparent;
                    color: $main-color;
                    transition: $transition;

                    &:hover,
                    &:focus {
                        color: $black-color;
                    }
                }
            }
        }
        &.widget_categories {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    position: relative;
                    border-top: 1px solid #eeeeee;
                    padding: {
                        top: 10px;
                        bottom: 10px;
                        left: 18px;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 10px;
                        height: 10px;
                        background: $main-color;
                        margin-top: -5px;
                        transition: $transition;
                    }
                    &:last-child {
                        border-bottom: 1px solid #eeeeee;
                    }
                    a {
                        color: $paragraph-color;

                        &:hover {
                            color: $main-color;
                        }
                    }
                    &:hover {
                        &::before {
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
        &.widget_recent_entries {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    position: relative;
                    padding-left: 100px;
                    margin-bottom: 15px;

                    a {
                        display: block;

                        img {
                            position: absolute;
                            left: 0;
                            top: 3px;
                            width: 85px;
                            height: 65px;
                        }
                    }
                    h5 {
                        font: {
                            size: 15px;
                            weight: 600;
                        }
                        line-height: 22px;
                        margin-bottom: 2px;

                        a {
                            color: $black-color;
                            display: inline-block;

                            &:hover,
                            &:focus {
                                color: $main-color;
                            }
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &.widget_tag_cloud {
            .tagcloud {
                a {
                    font-size: 15px !important;
                    border: 1px dashed #eeeeee;
                    padding: 7px 20px;
                    margin-top: 6px;
                    color: $paragraph-color;
                    display: inline-block;
                    margin-right: 5px;

                    &:hover {
                        background: $main-color;
                        color: $white-color;
                        border-color: $main-color;
                    }
                }
            }
        }
        &.widget_archive {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    position: relative;
                    border-top: 1px solid #eeeeee;
                    padding: {
                        top: 10px;
                        bottom: 10px;
                        left: 18px;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 10px;
                        height: 10px;
                        background: $main-color;
                        margin-top: -5px;
                        transition: $transition;
                    }
                    &:last-child {
                        border-bottom: 1px solid #eeeeee;
                    }
                    a {
                        color: $paragraph-color;
                        display: inline-block;

                        &:hover {
                            color: $main-color;
                        }
                    }
                    &:hover {
                        &::before {
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Footer CSS
=================================================*/
.footer-area {
    background-color: $black-color;
    padding-top: 100px;
    position: relative;
    z-index: 1;

    .br-line {
        position: absolute;
        top: -30px;
        left: 0;
        right: 0;
        margin: 0 auto;

        &::after,
        &::before {
            background: $black-color;
        }
    }
    .footer-shape1,
    .footer-shape2 {
        position: absolute;
        z-index: -1;
    }
    .footer-shape1 {
        left: 0;
        top: 0;
    }
    .footer-shape2 {
        right: 0;
        bottom: 0;
    }
}
.single-footer-widget {
    p {
        color: #999999;
        text-align: justify;
    }
    .logo {
        margin-bottom: 20px;
    }
    h3 {
        margin: 0;
        margin-top: -5px;
        color: $white-color;
        font: {
            size: 22px;
            weight: 700;
        }
    }
    .bar {
        width: 50px;
        height: 2px;
        background: $main-color;
        margin: {
            top: 4px;
            bottom: 25px;
        }
    }
    .contact-info {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            margin-bottom: 10px;

            a {
                color: #999999;

                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .social {
        padding: 0;
        margin: 15px 0 0;
        list-style-type: none;

        li {
            display: inline-block;
            margin-right: 7px;

            a {
                width: 32px;
                text-align: center;
                height: 32px;
                line-height: 30px;
                color: $white-color;
                background: $black-color;
                border-radius: 50%;
                border: 1px solid $main-color;

                &:hover,
                &:focus {
                    background: $main-color;
                    color: $white-color;
                }
                i {
                    &::before {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
.copyright-area {
    margin-top: 70px;
    border-top: 1px solid #151515;
    position: relative;
    padding: {
        top: 20px;
        bottom: 20px;
    }
    p {
        color: #999999;
    }
    ul {
        text-align: right;
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            position: relative;
            display: inline-block;
            margin-left: 17px;

            &:first-child {
                margin-left: 0;

                &::before {
                    display: none;
                }
            }
            &::before {
                content: "";
                position: absolute;
                left: -13px;
                bottom: 10px;
                width: 6px;
                height: 1px;
                background: #999999;
            }
            a {
                color: #999999;

                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
        }
    }
    .go-top {
        position: absolute;
        right: 0;
        top: -20px;
        left: 0;
        margin: 0 auto;
        text-align: center;
        width: 38px;
        text-align: center;
        line-height: 38px;
        height: 38px;
        background: $black-color;
        border-radius: 50%;
        color: $white-color;
        transition: $transition;
        cursor: pointer;

        &:hover,
        &:focus {
            color: $white-color;
            background: $main-color;
        }
    }
}

/*================================================
Dark Home CSS
=================================================*/
.uk-dark {
    .uk-h1,
    .uk-h2,
    .uk-h3,
    .uk-h4,
    .uk-h5,
    .uk-h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $white-color;
    }
    p {
        color: $paragraph-color2;
    }
    .uk-button-default {
        color: $white-color;

        &:hover,
        &:focus {
            background: $black-color;
        }
    }

    &.header-area {
        &.uk-sticky-fixed {
            background-color: $black-color;
        }
        .lang {
            form {
                button {
                    color: $white-color;
                }
            }
        }
        #navbar-toggle {
            span {
                background-color: $white-color;
            }
        }
        &.uk-dark {
            background-color: transparent;

            &.uk-sticky-fixed {
                background-color: $black-color;
            }
        }
    }
    .navbar {
        .uk-navbar-nav {
            li {
                a {
                    color: $white-color;

                    &:hover,
                    &:focus {
                        color: $main-color;

                        &::before {
                            opacity: 1;
                            visibility: visible;
                            width: 100%;
                        }
                    }
                }
                &.uk-active {
                    a {
                        color: $main-color;

                        &::before {
                            opacity: 1;
                            visibility: visible;
                            width: 100%;
                        }
                    }
                }
            }
        }
        .lang {
            form {
                select {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
        }
    }

    .main-banner-content {
        .video-btn {
            .uk-icon {
                background-color: $black-color;
            }
            &:hover,
            &:focus {
                .uk-icon {
                    background-color: $white-color;
                    color: $black-color;
                    border-color: $white-color;
                }
            }
        }
    }

    .single-features-box {
        background-color: $sub-black-color;

        .bar {
            &::before,
            &::after {
                background: $sub-black-color;
            }
        }
        &.active,
        &:focus,
        &:hover {
            background-color: $main-color;

            .bar {
                &::before,
                &::after {
                    background: $main-color;
                }
            }
        }
    }

    .section-title {
        .bar {
            &::before,
            &::after {
                background: $black-color;
            }
        }
    }

    .single-services {
        background-color: $sub-black-color;

        .icon {
            background-color: $black-color;
        }
        h3 {
            a {
                color: $white-color;
            }
        }
        &.active,
        &:focus,
        &:hover {
            background-color: $main-color;

            .icon {
                background-color: $white-color;
            }
        }
    }

    .about-img {
        img {
            filter: grayscale(100%);
            transition: $transition;
        }
        .shape-img {
            filter: unset;
        }
        &:hover,
        &:focus {
            img {
                filter: unset;
            }
        }
    }

    .single-project {
        .project-img {
            img {
                transition: $transition;
                filter: grayscale(100%);
            }
        }
        .project-content {
            background-color: $sub-black-color;

            h3 {
                a {
                    color: $white-color;
                }
            }
            ul {
                li {
                    a {
                        color: $paragraph-color2;
                    }
                }
            }
        }
        &:hover,
        &:focus {
            .project-content {
                ul {
                    li {
                        a {
                            color: $white-color;
                        }
                    }
                }
            }
            .project-img {
                img {
                    filter: unset;
                }
            }
        }
    }

    .feedback-img {
        img {
            filter: grayscale(100%);
            transition: $transition;

            &.shape-img {
                filter: unset;
            }
        }
        &:hover,
        &:focus {
            img {
                filter: unset;
            }
        }
    }
    .feedback-slides {
        &.owl-theme {
            .owl-nav {
                [class*="owl-"] {
                    color: $white-color;

                    &:hover,
                    &:focus {
                        color: $main-color;
                    }
                }
            }
        }
    }

    &.partner-area {
        border-bottom-color: $sub-black-color;
    }

    .single-team {
        .team-content {
            background-color: $black-color;

            span {
                color: $paragraph-color2;
            }
        }
        img {
            filter: grayscale(100%);
            transition: $transition;
        }
        &:hover,
        &:focus {
            img {
                filter: unset;
            }
        }
    }

    &.subscribe-area {
        background-color: $sub-black-color;
    }

    .single-blog-post {
        .blog-post-image {
            img {
                filter: grayscale(100%);
                transition: $transition;
            }
        }
        .blog-post-content {
            background-color: $sub-black-color;
            border: none;

            h3 {
                a {
                    color: $white-color;

                    &:hover,
                    &:focus {
                        color: $main-color;
                    }
                }
            }
        }
        &:hover,
        &:focus {
            .blog-post-image {
                img {
                    filter: unset;
                }
            }
        }
    }

    .map-img {
        .location {
            a {
                .location-info {
                    background-color: $sub-black-color;

                    h5 {
                        color: $white-color;
                    }
                    span {
                        color: $paragraph-color2;
                    }
                    &::before {
                        background: $sub-black-color;
                    }
                }
            }
        }
    }

    &.footer-area {
        background-color: $sub-black-color;
    }
    .single-footer-widget {
        .social {
            li {
                a {
                    color: #999999;
                    background: $sub-black-color;

                    &:hover,
                    &:focus {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
            }
        }
    }

    #contactForm {
        .uk-input,
        .uk-textarea {
            color: $white-color;
            background-color: $sub-black-color;
        }
    }

    .br-line {
        &::after,
        &::before {
            background: $black-color;
        }
    }
}
