/*
Flaticon icon font: Flaticon
Creation date: 27/03/2019 05:25
*/

@font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.eot");
    src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Flaticon.woff2") format("woff2"),
        url("../fonts/Flaticon.woff") format("woff"),
        url("../fonts/Flaticon.ttf") format("truetype"),
        url("../fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
    }
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: 15px;
    font-style: normal;
}

.flaticon-quality:before {
    content: "\f100";
}
.flaticon-domain-registration:before {
    content: "\f101";
}
.flaticon-targeting:before {
    content: "\f102";
}
.flaticon-search-engine:before {
    content: "\f103";
}
.flaticon-idea:before {
    content: "\f104";
}
.flaticon-pay-per-click:before {
    content: "\f105";
}
.flaticon-analytics:before {
    content: "\f106";
}
.flaticon-stats:before {
    content: "\f107";
}
.flaticon-link:before {
    content: "\f108";
}
.flaticon-translation:before {
    content: "\f109";
}
.flaticon-bug:before {
    content: "\f10a";
}
.flaticon-mail:before {
    content: "\f10b";
}
.flaticon-magnifying-glass:before {
    content: "\f10c";
}
.flaticon-right:before {
    content: "\f10d";
}
.flaticon-plan:before {
    content: "\f10e";
}
.flaticon-think:before {
    content: "\f10f";
}
.flaticon-shout:before {
    content: "\f110";
}
.flaticon-analytics-1:before {
    content: "\f111";
}
.flaticon-ux-design:before {
    content: "\f112";
}
.flaticon-camera:before {
    content: "\f113";
}
.flaticon-data:before {
    content: "\f114";
}
.flaticon-chat:before {
    content: "\f115";
}
.flaticon-project:before {
    content: "\f116";
}
.flaticon-quote:before {
    content: "\f117";
}
.flaticon-logo:before {
    content: "\f118";
}
.flaticon-twitter:before {
    content: "\f119";
}
.flaticon-linkedin:before {
    content: "\f11a";
}
.flaticon-logo-1:before {
    content: "\f11b";
}
.flaticon-down-arrow:before {
    content: "\f11c";
}
.flaticon-back:before {
    content: "\f11d";
}
.flaticon-multimedia:before {
    content: "\f11e";
}
.flaticon-search:before {
    content: "\f11f";
}
.flaticon-edit:before {
    content: "\f120";
}
.flaticon-plus:before {
    content: "\f121";
}
.flaticon-line:before {
    content: "\f122";
}
.flaticon-tick:before {
    content: "\f123";
}
.flaticon-chevron:before {
    content: "\f124";
}
